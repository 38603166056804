export function TerminalIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 5L6.5 6.5L4.5 8M7.5 8H9.5M3.5 13.5H12.5C12.8978 13.5 13.2794 13.342 13.5607 13.0607C13.842 12.7794 14 12.3978 14 12V4C14 3.60218 13.842 3.22064 13.5607 2.93934C13.2794 2.65804 12.8978 2.5 12.5 2.5H3.5C3.10218 2.5 2.72064 2.65804 2.43934 2.93934C2.15804 3.22064 2 3.60218 2 4V12C2 12.3978 2.15804 12.7794 2.43934 13.0607C2.72064 13.342 3.10218 13.5 3.5 13.5Z"
        stroke="#25241D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
