export function PluginIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1096 5.3234L18.6237 11.8375L21.2507 9.2105C21.673 8.7881 21.9292 8.22301 21.932 7.62566C21.9518 3.45517 20.5652 2.11579 16.3569 2.03754C15.7387 2.02604 15.149 2.28397 14.7118 2.72115L12.1096 5.3234ZM18.5178 9.29537L14.7047 5.48224L15.5499 4.63705C15.9609 4.22599 16.5084 3.96643 17.0898 3.96737C19.3096 3.97097 20.0933 4.7151 20.0507 6.94391C20.04 7.50565 19.783 8.03013 19.3857 8.42742L18.5178 9.29537Z"
        fill="#5D5D4C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.38589 19.3431C3.14102 19.588 2.99248 19.9314 3.07357 20.2681C3.17102 20.6726 3.37264 20.8987 3.81042 20.9962C4.13286 21.068 4.45693 20.9201 4.69052 20.6865L7.29026 18.0867C9.05378 18.8628 10.1033 18.9706 11.6778 18.5196C12.0338 18.4177 12.3518 18.2152 12.6138 17.9533L14.3339 16.2331C14.8019 15.7651 14.8019 15.0064 14.3339 14.5384L13.8043 14.0088L16.0287 11.7845C16.2627 11.5505 16.2627 11.1711 16.0287 10.9371L15.6579 10.5664C15.424 10.3324 15.0446 10.3324 14.8106 10.5664L12.5863 12.7907L11.2623 11.4667L13.4866 9.24241C13.7206 9.00841 13.7206 8.62904 13.4866 8.39505L13.0629 7.97137C12.8289 7.73737 12.4495 7.73737 12.2155 7.97137L9.99122 10.1957L9.46162 9.66609C8.99363 9.1981 8.23488 9.1981 7.7669 9.66609L6.09228 11.3407C5.80133 11.6317 5.58502 11.9919 5.49124 12.3926C5.09074 14.1036 5.24755 15.1421 5.96625 16.7627L3.38589 19.3431ZM12.5863 15.2269L8.77314 11.4138L7.90773 12.2792C7.50891 12.678 7.25222 13.2045 7.24114 13.7684C7.19569 16.0813 7.99418 16.8154 10.2343 16.7617C10.7963 16.7482 11.3212 16.492 11.7187 16.0945L12.5863 15.2269Z"
        fill="#5D5D4C"
      />
    </svg>
  );
}
