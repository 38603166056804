export function TrashIcon() {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2833 6.50001L9.995 14M6.005 14L5.71667 6.50001M14.0233 3.82501C14.3083 3.86834 14.5917 3.91417 14.875 3.96334M14.0233 3.82501L13.1333 15.3942C13.097 15.8652 12.8842 16.3051 12.5375 16.626C12.1908 16.9469 11.7358 17.1251 11.2633 17.125H4.73667C4.26425 17.1251 3.80919 16.9469 3.46248 16.626C3.11578 16.3051 2.90299 15.8652 2.86667 15.3942L1.97667 3.82501M14.0233 3.82501C13.0616 3.6796 12.0948 3.56925 11.125 3.49417M1.97667 3.82501C1.69167 3.86751 1.40833 3.91334 1.125 3.96251M1.97667 3.82501C2.93844 3.67961 3.9052 3.56926 4.875 3.49417M11.125 3.49417V2.73084C11.125 1.74751 10.3667 0.927507 9.38333 0.896674C8.46135 0.867206 7.53865 0.867206 6.61667 0.896674C5.63333 0.927507 4.875 1.74834 4.875 2.73084V3.49417M11.125 3.49417C9.04477 3.33341 6.95523 3.33341 4.875 3.49417"
        stroke="#BF1818"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
