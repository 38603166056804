export function ChainIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.29335 5.79202C9.72657 5.99886 10.1038 6.30664 10.3934 6.68951C10.683 7.07238 10.8765 7.51916 10.9576 7.99232C11.0388 8.46548 11.0052 8.9512 10.8596 9.40867C10.7141 9.86614 10.4609 10.282 10.1214 10.6214L7.12135 13.6213C6.55874 14.184 5.79567 14.5 5.00002 14.5C4.20436 14.5 3.4413 14.184 2.87869 13.6213C2.31607 13.0587 2 12.2957 2 11.5C2 10.7044 2.31607 9.9413 2.87869 9.37868L4.05002 8.20735M12.95 7.79268L14.1213 6.62135C14.684 6.05874 15 5.29567 15 4.50002C15 3.70436 14.684 2.9413 14.1213 2.37869C13.5587 1.81607 12.7957 1.5 12 1.5C11.2044 1.5 10.4413 1.81607 9.87868 2.37869L6.87868 5.37868C6.53911 5.71802 6.28593 6.13389 6.14041 6.59137C5.99488 7.04884 5.96127 7.53456 6.0424 8.00772C6.12352 8.48087 6.31701 8.92765 6.60661 9.31053C6.89621 9.6934 7.27347 10.0012 7.70669 10.208"
        stroke="#25241D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
