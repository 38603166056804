export function ConfirmedCheck() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6 8.5L7.5 10L10 6.5M14 8C14 8.84533 13.58 9.59333 12.938 10.0453C13.0072 10.4405 12.9801 10.8465 12.8591 11.229C12.738 11.6115 12.5266 11.9592 12.2427 12.2427C11.9592 12.5266 11.6115 12.738 11.229 12.8591C10.8465 12.9801 10.4405 13.0072 10.0453 12.938C9.81481 13.2663 9.50857 13.5343 9.15253 13.7191C8.7965 13.904 8.40117 14.0003 8 14C7.15467 14 6.40667 13.58 5.95467 12.938C5.55949 13.0071 5.15348 12.98 4.77099 12.859C4.38851 12.7379 4.04081 12.5265 3.75733 12.2427C3.47342 11.9592 3.26199 11.6115 3.14095 11.229C3.01991 10.8465 2.99283 10.4405 3.062 10.0453C2.73368 9.81481 2.46575 9.50857 2.28088 9.15253C2.09602 8.7965 1.99967 8.40117 2 8C2 7.15467 2.42 6.40667 3.062 5.95467C2.99283 5.55949 3.01991 5.15346 3.14095 4.77097C3.26199 4.38847 3.47342 4.04078 3.75733 3.75733C4.04081 3.47346 4.38851 3.26206 4.77099 3.14103C5.15348 3.01999 5.55949 2.99289 5.95467 3.062C6.18523 2.73372 6.49148 2.46582 6.8475 2.28096C7.20353 2.0961 7.59885 1.99973 8 2C8.84533 2 9.59333 2.42 10.0453 3.062C10.4405 2.99289 10.8465 3.01999 11.229 3.14103C11.6115 3.26206 11.9592 3.47346 12.2427 3.75733C12.5265 4.04081 12.7379 4.38851 12.859 4.77099C12.98 5.15347 13.0071 5.55949 12.938 5.95467C13.2663 6.18519 13.5343 6.49143 13.7191 6.84747C13.904 7.2035 14.0003 7.59884 14 8Z"
        stroke="#1A6CBC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
